import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import './RegisterPage.scss';
import LoginHeaderImage from '../../assets/images/login-header-2.svg';
import InputCustom from "../../components/forms/InputCustom";
import {login, register, getProfile} from "../../store/actions/auth";
import route from "../../helpers/route";
import {connect} from "react-redux";
import {useFormik} from "formik";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import * as Yup from "yup";
import commonAPI from "../../api/commonAPI";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import useAlerts from "../../hooks/useAlerts";
import ButtonCustom from "../../components/forms/ButtonCustom";
import isHubspotValid from '../../helpers/hubspot-email-validation';

const RegisterPage = ({register, login, getProfile}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const alerts = useAlerts();
  const query = new URLSearchParams(window.location.search);
  const redirect_url = query.get('redirect_url');

  const checkEmail = useDebouncedCallback( email => {
    return commonAPI.checkEmailUnavailable(email);
  }, 500,[commonAPI.checkEmailUnavailable])

  const formik = useFormik({
    initialValues: {
      email: '',
      email_confirmation: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await register(values.email, values.password, redirect_url);

        try{
          await login(values.email, values.password, true);
          history.replace({
            pathname: route('onboarding'),
            search: redirect_url ? `?redirect_url=${redirect_url}` : ''
          });
        }catch(e){
          alerts.add({title: 'Error', message: 'Ocurrio un error al ingresar a su cuenta'});
          history.replace(route('login'));
        }
      }catch(e){
        setFeedback({message: 'Ocurrio un error al registrarse.', success: false});
        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Email invalido')
        .required('Obligatorio')
        .test(
          'is-hubspot-valid',
          'Email invalido.',
          isHubspotValid
        )
        .test(
          'is-available',
          'El correo ya esta registrado, ¿Ya tiene cuenta?',
          async email => ((await checkEmail(email)).data === false)
        ),
      email_confirmation: Yup.string()
        .email('Email invalido')
        .required('Obligatorio')
        .oneOf([Yup.ref('email'), null], 'Los emails no coinciden'),
      password: Yup.string()
        .required('Obligatorio').min(8, 'Minimo 8 caracteres'),
    }),
  });

  return (
    <EmptyLayout>
      <div className="RegisterPage auth-content__inner">
        <div className="register">
          <div className="">
            <img className="register__header" src={LoginHeaderImage} alt="logo"/>
          </div>
          <h3 className="register__title auth-title">
            Registro de comercio
          </h3>
          <Form className="register__form" onSubmit={formik.handleSubmit}>
            <Form.Group>
              <InputCustom
                title="Ingresá tu mail"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                id="email"
                type="email"
                disabled={loading}
                error={formik.touched.email && formik.errors.email}
              />
            </Form.Group>

            <Form.Group>
              <InputCustom
                title="Confirmá tu mail"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email_confirmation"
                value={formik.values.email_confirmation}
                id="email_confirmation"
                type="email"
                disabled={loading}
                error={formik.touched.email_confirmation && formik.errors.email_confirmation}
              />
            </Form.Group>

            <Form.Group>
              <InputCustom
                title="Contraseña"
                autoComplete="off"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                id="password"
                type="password"
                disabled={loading}
                error={formik.touched.password && formik.errors.password}
              />
            </Form.Group>

            <div>
              {feedback && (
                <div className={`auth__feedback ${feedback.success ? 'auth__feedback--success' : 'auth__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}

              <ButtonCustom
                type="submit"
                className="register__btn auth-btn"
                size="lg"
                block={true}
                variant="primary"
                loading={loading}
              >
                Registrar
              </ButtonCustom>
            </div>

            <div className="register__option-container">
              <h5>
                <br/>
                ¿Ya tenes cuenta?
                <Link className="register__option register__option--login" to={route('login')}>
                  Iniciar sesión
                </Link>
              </h5>
            </div>
          </Form>
        </div>
      </div>
    </EmptyLayout>
  );
}

RegisterPage.propTypes = {

};

const mapDispatchToProps = {
  register,
  login,
  getProfile,
};

export default connect(null, mapDispatchToProps)(RegisterPage);