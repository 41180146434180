import React, {useState} from 'react';
import {Button, Form, Col, Row, Tooltip, OverlayTrigger} from "react-bootstrap";
import {Link, Redirect, useHistory} from "react-router-dom";
import './LoginPage.scss';
import LoginHeaderImage from '../../assets/images/login-header-2.svg';
import InputCustom from "../../components/forms/InputCustom";
import {login, getProfile} from "../../store/actions/auth";
import route from "../../helpers/route";
import {connect} from "react-redux";
import {useFormik} from "formik";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import * as Yup from "yup";
import ButtonCustom from "../../components/forms/ButtonCustom";
import useAlerts from "../../hooks/useAlerts";

const LoginPage = ({login, getProfile, intendedUrl, isUserLoading}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  //const [redirection, setRedirection] = useState(null);
  const alerts = useAlerts();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await login(values.email, values.password, values.remember);
        //history.push(intendedUrl || route('index'));
      }catch(e){
        if(e.profileError){
          alerts.add({title: 'Error', message: 'Ocurrio un error al ingresar a su cuenta.'});
          return;
        }

        if(e.response?.data.error === 'invalid_grant'){
          setFeedback({message: 'El usuario o contraseña son incorrectos.', success: false});
        }else if(e.response?.data.error === 'disabled'){
          setFeedback({message: 'Su usuario no esta habilitado.', success: false});
        }else if(e.response?.data.error === 'business_disabled'){
          setFeedback({message: 'Su comercio no esta habilitado.', success: false});
        }else{
          setFeedback({message: 'Ocurrio un error al ingresar.', success: false});
        }

        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Email invalido')
        .required('Obligatorio'),
      password: Yup.string()
        .required('Obligatorio'),
    }),
  });

  return (
    <EmptyLayout>
      <div className="LoginPage auth-content__inner">
        <div className="login">
          <div className="">
            <img className="login__header" src={LoginHeaderImage} alt="logo"/>
          </div>
          <h3 className="login__title auth-title">
            Iniciar sesión
          </h3>
          <Form className="login__form" onSubmit={formik.handleSubmit}>
            <Form.Group>
              <InputCustom
                title="Usuario"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                id="email"
                type="email"
                disabled={loading}
                error={formik.touched.email && formik.errors.email}
              />
            </Form.Group>

            <Form.Group>
              <InputCustom
                title="Contraseña"
                autoComplete="off"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                id="password"
                type="password"
                disabled={loading}
                error={formik.touched.password && formik.errors.password}
              />
            </Form.Group>

            <Row>
              {/*<Col xs={12} sm={6}>
                <Form.Group controlId="loginRememberMe">
                  <Form.Check
                    type="checkbox"
                    onChange={formik.handleChange}
                    name="remember"
                    checked={formik.values.remember}
                    label="Recuerdame"
                  />
                </Form.Group>
              </Col>*/}
              <Col xs={12} sm={6}>
                <Link className="login__option login__option--recover-password" to={route('forgot_password')}>
                  Recuperar contraseña
                </Link>
              </Col>
            </Row>

            <div>
              {feedback && (
                <div className={`auth__feedback ${feedback.success ? 'auth__feedback--success' : 'auth__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}

              <ButtonCustom
                type="submit"
                className="login__btn auth-btn"
                size="lg"
                block={true}
                variant="primary"
                loading={loading}
              >
                Ingresar
              </ButtonCustom>
            </div>

            <div className="login__option-container">
              {/*<OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip id="new-user-tooltip">
                    Para agregar un usuario a su comercio debe ingresar con su cuenta y dar de alta los
                    nuevos usuarios desde el panel de control.
                  </Tooltip>
                )}
              >
                <Link className="login__option login__option--new-user" to="#">
                  Dar de alta un nuevo usuario para mi comercio
                </Link>
              </OverlayTrigger>*/}
              <h5>
                <br/>
                ¿Sos nuevo?&nbsp;
                <Link className="login__option login__option--register" to={route('register')}>
                  Registra tu comercio aqui
                </Link>
              </h5>
            </div>
          </Form>
        </div>
      </div>
    </EmptyLayout>
  );
}

LoginPage.propTypes = {

};

const mapStateToProps = state => ({
  intendedUrl: state.auth.intendedUrl,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  login,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);