import React from 'react';
import PropTypes from 'prop-types'; //
import {connect} from "react-redux";
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './HomePage.scss';
import {Col, Container} from "react-bootstrap";
import HomeWorldCupProde from '../../components/HomeWorldCupPromo/HomeWorldCupProde';
import calularcuota from '../../assets/images/calularcuota.png'
import calularcuota_desk from'../../assets/images/calularcuota_desk.png'
import simularfinanciacion from '../../assets/images/simularfinanciacion.png'
import simularfinanciacion_desk from'../../assets/images/simularfinanciacion_desk.png'
import diaamigo_desk from '../../assets/images/diaamigo_desk.png';
import diaamigo_original from '../../assets/images/diaamigo.png';

const HomePage = ({
                    title,
                    children,
                    backAction,
                    topbarContent,
                    user,
                    isUserLoading,
                    token,
                    openSideMenu,
                    logout
}) => {

    const isProduction = process.env.NODE_ENV === 'production';
      const handleParticipaClick = () => {
        let localUrl = process.env.REACT_APP_LOCAL_URL ? process.env.REACT_APP_LOCAL_URL : 'https://agencias.decreditos.com';
        let url = localUrl + '/agencias/notify/emailFront' + `?access_token=${token}`;
        window.open(url, '_blank');
      };
    
      const mostrarDiaAmigo = process.env.REACT_APP_MOSTRAR_PROMOCION ==='true'
  return (
    <DefaultLayout title="Inicio">
      <div className="HomePage">
        <div className='container mt-2'>
            <div className='row'>
            {mostrarDiaAmigo && (
              <div className='col-12 text-center mt-1 d-block d-sm-none'>
                <div className="d-inline-block position-relative" style={{ marginBottom: '10px' }}>
                  <img src={diaamigo_desk} className="img-fluid" alt="Dia Amigo" style={{ marginBottom: '5px' }} />
                  <button className="participa-button" style={{ color: '#095976', backgroundColor: '#fff', width: '250px', height: '40px', borderRadius: '14px', position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)', fontWeight: 'bold', border: '2px solid #fff', fontSize: '22px' }} onClick={handleParticipaClick}>
                    Participar
                  </button>
                </div>
              </div>
            )}
                <div className='col-12 text-center mt-1 d-block d-sm-none'>
                    <a href='https://agencias.decreditos.com/agencias/simulacion/inicio'>
                    <img src={simularfinanciacion} className="img-fluid" />
                    </a>
                </div>
                <div className='col-12 mt-2 text-center d-block d-sm-none'>
                    <a href={`https://${isProduction ? 'agencias2.decreditos.com' : 'agencias2.decreditoslabs.com'}?user=${user ? user.email : ''}&id=${user ? user.business.code : ''}&name=${user ? user.personal_data.first_name + user.personal_data.last_name : ''}&resource=simulador`}>
                    <img src={calularcuota} className="img-fluid" />
                    </a>
                </div>
                {mostrarDiaAmigo && (
              <div className='col-12 mt-3 text-center d-none d-sm-block'>
                <div className="d-inline-block position-relative" style={{ marginBottom: '-32px' }}>
                  <img src={diaamigo_original} className="img-fluid" alt="Dia Amigo" style={{ width: '80%', height: 'auto', marginBottom: '5px' }} />
                  <button className="participa-button" style={{ color: '#095976', backgroundColor: '#fff', width: '250px', height: '40px', borderRadius: '14px', position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)', fontWeight: 'bold', border: '2px solid #fff', fontSize: '22px' }} onClick={handleParticipaClick}>
                    Participar
                  </button>
                </div>
              </div>
            )}
                <div className='col-12 mt-5 text-center d-none d-sm-block'>
                    <a href='https://agencias.decreditos.com/agencias/simulacion/inicio'>
                    <img src={simularfinanciacion_desk} className="img-fluid" />
                    </a>
                </div>
                <div className='col-12 mt-2 text-center d-none d-sm-block'>
                    <a href={`https://${isProduction ? 'agencias2.decreditos.com' : 'agencias2.decreditoslabs.com'}?user=${user ? user.email : ''}&id=${user ? user.business.code : ''}&name=${user ? user.personal_data.first_name + user.personal_data.last_name : ''}&resource=simulador`}>
                    <img src={calularcuota_desk} className="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

HomePage.propTypes = {
    user: PropTypes.shape({
      menu: PropTypes.object,
      impersonating: PropTypes.shape({
        logout_url: PropTypes.string,
        imposter_name: PropTypes.string,
        imposter_identifier: PropTypes.string,
      }),
    }),
    sideMenuIsOpen: PropTypes.bool.isRequired,
    closeSideMenu: PropTypes.func.isRequired,
    openSideMenu: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

const mapStateToProps = state => ({
    sideMenuIsOpen: state.menu.sideMenuIsOpen,
    user: state.auth.user,
    token: state.auth.tokens?.access_token,
    isUserLoading: state.auth.isUserLoading,
  });

export default connect(mapStateToProps)(HomePage);