import React, {useCallback, useMemo, useState, useEffect} from "react";
import './OnboardingMenu.scss';
import Backdrop from "../Backdrop/Backdrop";
import PropTypes from 'prop-types';
import Spinner from "../Spinner/Spinner";
import Logo from '../../assets/images/decreditos-new-logo.png';
import {Badge, Collapse} from "react-bootstrap";
import {
  FaBell,
  FaCalculator,
  FaFacebook, 
  GoChevronDown, GoChevronUp,
  FaHome, FaInstagram, FaRecycle,
  FaWhatsapp,
} from "react-icons/all";

import { ReactComponent as Document } from '../../assets/icons/document.svg'
import { ReactComponent as Shield } from '../../assets/icons/shield.svg'
import { ReactComponent as VehicleGroup } from '../../assets/icons/vehicle-group.svg'
import { ReactComponent as Cart } from '../../assets/icons/cart.svg'
import { ReactComponent as User } from '../../assets/icons/user.svg'
import { ReactComponent as UserGroup } from '../../assets/icons/user-group.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'


/**
 *
 * @param isOpen
 * @param onOpenMenu
 * @param onCloseMenu
 * @param logout
 * @param menu
 * @param loading
 * @param token
 * @param platformKey
 * @param onInternalRedirect
 * @returns {JSX.Element}
 * @constructor
 */
const OnboardingMenu = ({ userData, isOpen, onOpenMenu, onCloseMenu, menu, loading, token, platformKey = null, onInternalRedirect }) => {
  return (
    <>
      <aside className={`OnboardingMenu ${isOpen ? 'OnboardingMenu--open' : ''}`}>
        <div className="OnboardingMenu__top">
          <div className="OnboardingMenu__logo-container">
            <img className="OnboardingMenu__logo" src={Logo} alt="Logo"/>
          </div>
          <div className="OnboardingMenu__user-container">
            {loading ? (<Spinner size={20}/>) : (
              <>
                <span>
                  ¡Hola {menu.user_data.fullname}!
                </span>
              </>
            )}
          </div>
        </div>
        <nav className="OnboardingMenu__navbar-container">
          {loading ? (
            <Spinner size={20}/>
          ) : (
            <Navbar
              userDataNavbar={userData}
              menu={menu}
              token={token}
              platformKey={platformKey}
              onInternalRedirect={onInternalRedirect}
              onCloseMenu={onCloseMenu}
            />
          )}
        </nav>
      </aside>
      {isOpen && (
        <Backdrop
          onClick={isOpen ? onCloseMenu : onOpenMenu}
          onBack={isOpen ? onCloseMenu : onOpenMenu}
          index={150}
          className="OnboardingMenu__backdrop"
        />
      )}
    </>
  )
}

OnboardingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  menu: PropTypes.object.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  onInternalRedirect: PropTypes.func.isRequired,
  token: PropTypes.string,
  platformKey: PropTypes.string,
};


/**
 *
 * @param ico
 * @returns {JSX.Element|null}
 * @constructor
 */
const AbstractIcon = ({ico}) => {
  switch(ico){
    case 'home': return <FaHome/>;
    case 'bell': return <FaBell/>;
    case 'recycle': return <FaRecycle/>;
    case 'file': return <Document/>;
    case 'cart': return <Cart/>;
    case 'shield': return <Shield/>;
    case 'car': return <VehicleGroup/>;
    case 'user': return <User/>;
    case 'users': return <UserGroup/>;
    case 'calculator': return <FaCalculator/>;
    case 'whatsapp': return <FaWhatsapp/>;
    case 'instagram': return <FaInstagram/>;
    case 'facebook': return <FaFacebook/>;
    default: return null;
  }
}

/**
 *
 * @param menu
 * @param closeSideMenu
 * @param token
 * @param logout
 * @param onInternalRedirect
 * @param platformKey
 * @param onCloseMenu
 * @returns {JSX.Element}
 * @constructor
 */
const Navbar = ({userDataNavbar, menu, closeSideMenu, token, onInternalRedirect, platformKey, onCloseMenu}) => {
  const [groupOpen, setGroupOpen] = useState(null);

  const groupToggleHandler = useCallback((groupKey) => {
    setGroupOpen(groupKey === groupOpen ? null : groupKey);
  }, [groupOpen]);
  
  return (
    <ul className="Navbar">
  
      {menu.items.map(line => (
        <NavbarLine
          key={line.id}
          closeSideMenu={closeSideMenu}
          line={{...line, userDataNavbar}}
          groupToggleHandler={groupToggleHandler}
          groupOpen={groupOpen}
          icon={<AbstractIcon ico={line.icon}/>}
          token={token}
          onInternalRedirect={onInternalRedirect}
          platformKey={platformKey}
          onCloseMenu={onCloseMenu}
        />
      ))}

      <li className="Navbar__filler"/>

      <NavbarItem className="NavbarItem__logout" to={menu.logout_url} icon={<Logout/>}>
        Cerrar Sesión
      </NavbarItem>
    </ul>
  )
}

/**
 *
 * @param line
 * @param groupOpen
 * @param groupToggleHandler
 * @param onCloseMenu
 * @param icon
 * @param token
 * @param onInternalRedirect
 * @param platformKey
 * @returns {JSX.Element|null}
 * @constructor
 */
const NavbarLine = ({ line, groupOpen, groupToggleHandler, onCloseMenu, icon, token, onInternalRedirect, platformKey}) => {

  let to = '/';

  const userDataNavbar = line.userDataNavbar;
  console.log(line);
  if(line.platform !== platformKey){
    to = line.url
    if(line.platform === 'agencies'){
      if(line.name === "Calcular cuotas"){
        if(process.env.REACT_APP_ENVIRONMENT == 'qa' || process.env.REACT_APP_ENVIRONMENT == 'localhost'){
          to = `https://agencias2.decreditoslabs.com/?user=${userDataNavbar ? userDataNavbar.email : null}&id=${userDataNavbar ? userDataNavbar.business.code : null}&name=${userDataNavbar ? userDataNavbar.personal_data.first_name + userDataNavbar.personal_data.last_name : null}&resource=simulador`;
        }else{
          to = `https://agencias2.decreditos.com/?user=${userDataNavbar ? userDataNavbar.email : null}&id=${userDataNavbar ? userDataNavbar.business.code : null}&name=${userDataNavbar ? userDataNavbar.personal_data.first_name + userDataNavbar.personal_data.last_name : null}&resource=simulador`;
        }
      }else{
        to += `?access_token=${token}`;
      }
    }else if(line.platform === 'insurance'){
      to += `?user=${userDataNavbar ? userDataNavbar.email : null}&id=${userDataNavbar ? userDataNavbar.business.code : null}&name=${userDataNavbar ? userDataNavbar.personal_data.first_name + userDataNavbar.personal_data.last_name : null}&resource=seguro`; 
    }
  }else{
    to = line.path || line.url;
  }

  const onClickHandler = useCallback((e) => {
    onCloseMenu && onCloseMenu();

    if(to.startsWith('/') && onInternalRedirect){
      e.preventDefault();
      onInternalRedirect(to);
    }

  }, [to]);

  switch(line.type){
    case 'item':
      return (
        <NavbarItem
          target={line.external_window ? '_blank' : ''}
          icon={icon}
          onClick={onClickHandler}
          line={userDataNavbar}
          to={to}
          badge={line.badge}
          //className={line.highlight ? 'NavbarItem--highlight' : ''}
          clasName={'divider'}
        >
          {line.name}
        </NavbarItem>
      )
    case 'group': return (
      <NavbarGroup
        groupKey={line.name}
        icon={icon}
        groupOpen={groupOpen}
        onToggle={groupToggleHandler}
        label={line.name}
        badge={line.badge}
      >
        {line.items.map(subLine => (
          <NavbarLine
            key={subLine.id}
            token={token}
            line={{...subLine, userDataNavbar}}
            groupOpen={groupOpen}
            onToggle={groupToggleHandler}
            platformKey={platformKey}
            onInternalRedirect={onInternalRedirect}
          />
        ))}
      </NavbarGroup>
    )
    case 'divider': return (<NavbarDivider/>)
    default:
      return null;
  }
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarDivider = ({}) => {
  return (
    <li className="NavbarDivider"/>
  );
}

/**
 *
 * @param icon
 * @param to
 * @param children
 * @param onClick
 * @param target
 * @param className
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItem = ({  line, icon, to, children, onClick, target, className, badge }) => {
  return (
    <li className={`NavbarItem ${className || ''}`}>
      <a href={to || '#'} onClick={onClick} target={target}>
        <span className="NavbarItem__icon">
          {icon}
        </span>
        {children}
        {badge && (<NavbarItemBadge text={badge}/>)}
      </a>
    </li>
  );
}

/**
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItemBadge = ({text }) => {
  return (
    <span className="NavbarItem__badge-container">
      <Badge variant="primary">
        {text}
      </Badge>
    </span>
  );
}

/**
 *
 * @param children
 * @param icon
 * @param groupKey
 * @param label
 * @param groupOpen
 * @param onToggle
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarGroup = ({children, icon, groupKey, label, groupOpen, onToggle, badge }) => {
  const onClick = useCallback(() => {
    onToggle(groupKey);
  }, [onToggle, groupKey]);

  const open = groupKey && groupKey === groupOpen;

  return (
    <li className="NavbarItem NavbarGroup">
      <a href='#' onClick={onClick}>
        <span className="NavbarItem__icon">
          {icon}
        </span>
        {label}
        {badge && (<NavbarItemBadge text={badge}/>)}
        <div className="NavbarGroup__collapse-ico">
          {open ? (<GoChevronUp/>) : (<GoChevronDown/>)}
        </div>
      </a>
      <Collapse in={open}>
        <div>
          <ul className="NavbarGroup__list">
            {children}
          </ul>
        </div>
      </Collapse>
    </li>
  );
}

export default OnboardingMenu;

export {
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarItem,
  NavbarItemBadge,
  NavbarLine,
  OnboardingMenu,
  AbstractIcon,
};